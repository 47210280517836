.map-container {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border: 2px solid #333333; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); 
  margin-top: 2%;
  width: 100%;
  height: 90%;
  margin-right: -1.5%;
  cursor: grab;
}

.map-container:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); 
}
  
.map-container:active {
  cursor: grabbing;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 1024px) {
  .map-container {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60vh;
  }

  #map {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60vh;
  }
}