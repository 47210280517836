.home-container {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.home-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: calc(100vh - 100px); 
  margin-top: 0.4%;
  padding: 0 20px;
}

@media (max-width: 1024px) {
  .home-content {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 0;
  }
}