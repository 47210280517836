.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5%;
  margin-top: 1.5%;
}

.navbar {
  background-color: #262626;
  padding: 10px 5%;
  border-radius: 8px;
  font-size: 1.5rem;
  text-align: center;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.2); 
}

.navbar ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar ul li a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar ul li a:hover {
  background-color: #565656;
}

.navbar ul li a.active {
  background-color: #ffffff;
  color: #262626;
}

@media (max-width: 1024px) {
  .navbar ul {
    flex-direction: column;
    align-items: center;
  }

  .navbar ul li {
    margin: 5px 0;
  }

  .header-container {
    flex-direction: column;
  }

  .logout-button {
    margin-top: 10px;
  }
}
