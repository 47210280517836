.customer-records-page {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.customer-records-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 1500px;
  width: auto;
  height: calc(100vh - 150px);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); 
  margin-top: 1.5%;
}

.customer-records-container:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); 
}

.customer-records-container h2 {
  color: #333;
  font-weight: bold;
}

.customer-records-table-container {
  flex: 1;
  height: calc(100vh - 250px);
  width: 80%;
  min-width: 900px;
  overflow-y: auto;
}

.customer-records-table {
  width: 80%;  
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: auto;
}

.customer-records-table th, .customer-records-table td {
  padding: 15px;
  text-align: center; 
  color: #333;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-weight: bold;
  font-size: 1.25rem;
}

.customer-records-table th {
  background-color: #262626;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-right: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

.customer-records-table th:first-child {
  border-top-left-radius: 8px;
}

.customer-records-table th:last-child {
  border-top-right-radius: 8px;
  border-right: none;
}

.customer-records-table td {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.customer-records-table td:last-child {
  border-right: none;
}

.customer-records-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.customer-records-table tr:hover td {
  background-color: #f1f1f1;
}

.customer-records-table td:first-child {
  border-left: none;
}

.customer-records-table td:last-child {
  border-right: none;
}

.customer-records-table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 50px;
  background-color: #f9f9f9;
}

.customer-records-search-bar-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  width: 300px;
}

.customer-records-search-bar-container:focus-within {
  border: 1px solid #333; 
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.1); 
}

.customer-records-search-bar {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
  width: 100%;
}

.customer-records-search-icon {
  color: #333;
  margin-right: 10px;
  font-size: 18px;
}

.customer-records-search-icon:focus {
  color: #262626;
}

.customer-records-button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.customer-records-table-controls .customer-records-button-group button {
  padding: 10px 15px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.customer-records-table-controls .customer-records-button-group button:hover {
  background-color: #333;
}

.customers-count {
  font-size: 1.5rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 20px;
}

.customers-count strong {
  background-color: white;
  color: #000;
  padding: 1px 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.city-column {
  width: 40%;
}

.customer-records-action-column {
  width: 20%;
}

.customer-item button {
  padding: 7px 12px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.customer-item button:hover {
  background-color: #333;
}