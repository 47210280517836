.forbidden-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
}

.forbidden-box {
  text-align: center;
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); 
}

.forbidden-box h2 {
  color: #000000;
  font-size: 2em;
  margin-bottom: 16px;
}

.forbidden-box p {
  color: #000000;
  font-size: 1.2em;
}
