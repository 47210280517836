.logout-button {
  background-color: #262626;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1em;
  transition: background-color 0.3s;
  position: absolute;
  right: 2.5%;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.2); 
}

.logout-button:hover {
  background-color: #565656;
}

.logout-icon {
  margin-right: 8px;
  font-size: 1.2em;
}
