/* Full-screen dark background */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #121212;
  padding: 1rem;
}

/* Login box with subtle shadow and rounded corners */
.login-box {
  background-color: #1e1e1e;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 350px;
}

/* Title styling */
.login-box h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

/* Form layout */
.login-box form {
  display: flex;
  flex-direction: column;
}

/* Input styling */
.login-box input {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #ffffff;
  font-size: 1rem;
}

/* Placeholder color */
.login-box input::placeholder {
  color: #bbbbbb;
}

/* Error message */
.login-box .error {
  color: #ff5252;
  text-align: center;
  margin-bottom: 1rem;
}

/* Button styling using neutral dark gray accents */
.login-box button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;  /* Neutral dark gray */
  color: #000000;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect: a slightly darker variant */
.login-box button:hover {
  background-color: #cccccc;  /* Slightly darker neutral gray */
}
