.new-order-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  margin-left: -5%;
}

.form-container {
  max-width: 90%;
  margin: 2rem auto;
  padding: 4%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); 
  position: relative;
}

.form-container:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); 
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem; 
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.form-group textarea, .form-group input, .form-group select {
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 2.5rem;
  resize: none;
  overflow: hidden;
}

.form-group textarea:focus, .form-group input:focus, .form-group select:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.1);
}

.form-group select:hover {
  cursor: pointer;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 0.6rem;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #333333;
}

.suggestions-list {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: auto;
  max-height: 275px; 
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-color: white;
  margin-top: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 5px;
  text-align: left;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.suggestions-list li {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  border-radius: 16px;
}

.suggestions-list li:last-child {
  border-bottom: none;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.suggestions-list li .title {
  font-weight: bold;
  font-size: 1em;
}

.suggestions-list li .subtitle {
  font-size: 0.85em;
  color: #666;
}

.suggestions-list::-webkit-scrollbar {
  display: none;
}

.highlight {
  color: #169cdc;
}

.tariff-dropdown {
  position: relative;
}

.tariff-dropdown-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 12px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 2.5rem;
}

.tariff-dropdown-button:focus {
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.1);
}

.tariff-dropdown-button svg {
  margin-left: 10px;
}

.tariff-dropdown-button:hover {
  background-color: #262626;
  color: white;
  transition: background-color 0.3s ease;
}

.tariff-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  overflow-y: auto;
  max-height: 25vh;
  height: auto;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.tariff-dropdown-menu .tariff-dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tariff-dropdown-menu .tariff-dropdown-item:hover {
  background-color: #262626;
  color: white;
}

.tariff-dropdown-menu .tariff-dropdown-item.selected {
  background-color: #333;
  color: white;
}

.tariff-dropdown.open .tariff-dropdown-menu {
  display: block;
}

@media (max-width: 1024px) {
  .new-order-container {
    margin: 0 auto;
  }

  .form-container {
    padding: 6%;
  }
}