/* General page styling */
.order-counts-page {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

/* Container styling */
.order-counts-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 1500px;
  width: auto;
  height: calc(100vh - 150px);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); 
  margin-top: 1.5%;
}

.order-counts-container:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); 
}

.order-counts-container h2 {
  color: #333;
  font-weight: bold;
}

/* Table container styling */
.order-counts-table-container {
  flex: 1;
  height: calc(100vh - 250px);
  width: 75%;
  min-width: 900px;
  overflow-y: auto;
}

/* Table styling */
.order-counts-table {
  width: 75%;  
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: auto;
}

.order-counts-table th, .order-counts-table td {
  padding: 15px;
  text-align: center; 
  color: #333;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-weight: bold;
  font-size: 1.25rem;
}

.order-counts-table th {
  background-color: #262626;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-right: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

.order-counts-table th:first-child {
  border-top-left-radius: 8px;
}

.order-counts-table th:last-child {
  border-top-right-radius: 8px;
  border-right: none;
}

.order-counts-table td {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.order-counts-table td:last-child {
  border-right: none;
}

.order-counts-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.order-counts-table tr:hover td {
  background-color: #f1f1f1;
}

.order-counts-table td:first-child {
  border-left: none;
}

.order-counts-table td:last-child {
  border-right: none;
}

/* Edit button styling */
.edit-button {
  padding: 10px 15px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #333;
}

/* Align the button to the center */
.order-counts-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.count-column {
  width: 145px;
}

.action-column {
  width: 68px;
}

/* Table controls styling */
.order-counts-table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
  background-color: #f9f9f9;
}

/* Radio button group styling */
.radio-button-group {
  display: flex;
  margin-bottom: 0;
}

.radio-button {
  position: relative;
  display: inline-block;
}

.radio-button input {
  display: none;
}

.radio-button .radio-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.radio-button input:checked + .radio-label {
  background-color: #333;
  color: white;
}

.radio-button .radio-label:hover {
  background-color: #262626;
  color: white;
  transition: background-color 0.3s ease;
}

/* Dropdown styling */
.dropdown {
  position: relative;
}

.dropdown-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-button.selected {
  background-color: #333;
  color: white;
}

.dropdown-button svg {
  margin-left: 10px;
}

.dropdown-button:hover {
  background-color: #262626;
  color: white;
  transition: background-color 0.3s ease;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
}

.dropdown-menu .dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #262626;
  color: white;
}

.dropdown-menu .dropdown-item.selected {
  background-color: #333;
  color: white;
}

.dropdown.open .dropdown-menu {
  display: block;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-buttons button[type="submit"] {
  background-color: #000;
  color: white;
}

.form-buttons button[type="button"] {
  background-color: #ddd;
  color: black;
}

.form-buttons button:hover {
  opacity: 0.8;
}

/* Modal styling */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: block;
}

.modal-content {
  background-color: #f9f9f9;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
  max-width: 500px;
  border-radius: 8px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}