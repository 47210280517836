body {
  margin: 0;
  font-family: 'ArialRounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "ArialRounded";
  src: local("ArialRounded"),
   url("./fonts/arial-rounded-mt/ARLRDBD_0.TTF") format("truetype");
}

.font-face-ar {
  font-family: "ArialRounded";
}